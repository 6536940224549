import "./galery.scss";

const section = document.querySelector("#galery");

let nextTimeout;

function next() {
  const currentActive = section.querySelector("img.galery--active");
  const nextActive =
    currentActive.nextElementSibling || section.firstElementChild;

  if (nextActive.src) {
    setActive(nextActive);
    return;
  }
  nextActive.addEventListener(
    "load",
    () => {
      setActive(nextActive);
    },
    { once: true }
  );

  if (nextActive.dataset.src) {
    nextActive.src = nextActive.dataset.src;
  }
  if (nextActive.dataset.srcset) {
    nextActive.srcset = nextActive.dataset.srcset;
  }
}

function setActive(nextActive) {
  const currentActive = document.querySelectorAll(".galery--active");

  nextActive.classList.add("galery--active");
  setTimeout(() => {
    currentActive.forEach((img) => {
      img.classList.remove("galery--active");
    });
  }, 1000);
  nextTimeout = setTimeout(next, 5000);
}

section.addEventListener("click", () => {
  console.log("click");
  clearTimeout(nextTimeout);
  next();
});

setTimeout(next, 2000);
